const MESSAGE_DISPLAY_TIME = 3000;

const WINDOW_WIDTH_1279 = 1279;
const WINDOW_WIDTH_989 = 989;
const WINDOW_WIDTH_629 = 629;

const QTY_MOVIES_WIDTH_MORE_1279 = 16;
const QTY_MOVIES_WIDTH_MORE_989 = 12;
const QTY_MOVIES_WIDTH_MORE_629 = 8;
const QTY_MOVIES_WIDTH_MOBILE = 5;

const QTY_ADD_MOVIES_WIDTH_MORE_1279 = 4;
const QTY_ADD_MOVIES_WIDTH_MORE_989 = 3;
const QTY_ADD_MOVIES_WIDTH_MORE_629 = 2;
const QTY_ADD_MOVIES_WIDTH_MORE_MOBILE = 2;

const MAX_LENGTH_SHORT_FILM = 40;

module.exports = {
  MESSAGE_DISPLAY_TIME,
  WINDOW_WIDTH_1279,
  WINDOW_WIDTH_989,
  WINDOW_WIDTH_629,
  QTY_MOVIES_WIDTH_MORE_1279,
  QTY_MOVIES_WIDTH_MORE_989,
  QTY_MOVIES_WIDTH_MORE_629,
  QTY_MOVIES_WIDTH_MOBILE,
  QTY_ADD_MOVIES_WIDTH_MORE_1279,
  QTY_ADD_MOVIES_WIDTH_MORE_989,
  QTY_ADD_MOVIES_WIDTH_MORE_629,
  QTY_ADD_MOVIES_WIDTH_MORE_MOBILE,
  MAX_LENGTH_SHORT_FILM,
};
